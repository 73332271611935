import React, { useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';

import { useDispatch, useSelector } from 'react-redux';
import { addCompany } from '../../features/users';

const AddCompanyForm = () => {
    const dispatch = useDispatch();

    const [name, setCompanyName] = useState('');
    const [type, setCompanyType] = useState('');
    const [eik, setCompanyEIK] = useState('');
    const [isDDS, setIsDDS] = useState(false);
    const [mol, setCompanyMOL] = useState('');
    const [country, setCompanyCountry] = useState('bg');
    const [city, setCompanyCity] = useState('');
    const [address, setCompanyAddress] = useState('');
    const [company_activity, setCompanyActivity] = useState('0');
    const [rent, setRent] = useState('1');
    const [insurance, setInsurance] = useState('1');
    const [hiredPeople, setHirdePeople] = useState(false);
    const [cash_register, setCach_register] = useState('1');
    const [bank_account, setBank_account] = useState('1');
    const [poss_account, setPoss_account] = useState('1');

    const [error, setError] = useState(null);
    const serverError = useSelector((state) => state.users.error);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!validator()) {
            return;
        }
        dispatch(addCompany({ name, type, eik, mol, country, city, address, company_activity, rent, insurance, hiredPeople, cash_register, bank_account, poss_account}));
    };

    const validator = () => {
        setError('');
        if (name.length < 3) {
            setError('Името на фирмата трябва да е поне 3 символа');
            return false;
        }
        if (type.length < 1) {
            setError('Mоля въведете тип на фирмата');
            return false;
        }
        if (eik.length < 9) {
            setError('Моля въведете валиден ЕИК');
            return false;
        }
        if (mol.length < 3) {
            setError('Моля въведете валиден МОЛ');
            return false;
        }
        if (city.length < 3) {
            setError('Моля въведете валиден град');
            return false;
        }
        if (address.length < 3) {
            setError('Моля въведете валиден адрес');
            return false;
        }
        if (company_activity === '0') {
            setError('Моля изберете дейност');
            return false;
        }
        return true;
    };


    const renderForm = (
        <>
            <Stack spacing={3}>
                <TextField
                    name="name"
                    label="Име на фирмата"
                    type="text"
                    value={name}
                    onChange={(e) => setCompanyName(e.target.value)}
                />

                <TextField
                    name="type"
                    label="Тип на фирмата"
                    type="text"
                    placeholder="ЕООД, ЕТ, ООД"
                    value={type}
                    onChange={(e) => setCompanyType(e.target.value)}
                />

                <TextField
                    name="eik"
                    label="ЕИК"
                    type="text"
                    value={eik}
                    onChange={(e) => setCompanyEIK(e.target.value)}
                />

                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isDDS} onChange={(e) => setIsDDS(e.target.checked)} />} label="Регистрация по ДДС" />
                </FormGroup>


                {isDDS && (
                    <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                        В момента не поддържаме регистрация по ДДС, очаквайте скоро! Може да предложим обслужване извън платформата - свържете се с нас за повече информация.
                    </Typography>
                )}

                <TextField
                    name="mol"
                    label="МОЛ"
                    type="text"
                    value={mol}
                    onChange={(e) => setCompanyMOL(e.target.value)}
                />
                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="rent">Държава</InputLabel>
                    <Select
                        id="country"
                        value={country}
                        label="Държава"
                        onChange={(e) => setCompanyCountry(e.target.value)}
                    >
                        <MenuItem value="bg">България</MenuItem>
                    </Select>
                </FormControl>

                <TextField
                    name="city"
                    label="Град"
                    type="text"
                    value={city}
                    onChange={(e) => setCompanyCity(e.target.value)}
                />

                <TextField
                    name="address"
                    label="Адрес"
                    type="text"
                    value={address}
                    onChange={(e) => setCompanyAddress(e.target.value)}
                />
                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="rent">Дейност</InputLabel>
                    <Select
                        id="deinost"
                        value={company_activity}
                        label="Дейност"
                        onChange={(e) => setCompanyActivity(e.target.value)}
                    >
                        <MenuItem value="0">Изберете дейност</MenuItem>
                        <MenuItem value="1">Фризьорски услуги</MenuItem>
                        <MenuItem value="2">Козметични услуги</MenuItem>
                        <MenuItem value="3">Маникюр и педикюр</MenuItem>
                        <MenuItem value="4">Масажи</MenuItem>
                        <MenuItem value="5">Автомонтьор</MenuItem>
                        <MenuItem value="6">Свободна професия/фрийлансър</MenuItem>
                        <MenuItem value="7">Заначтчия</MenuItem>
                        <MenuItem value="8">Земеделски производител</MenuItem>
                        <MenuItem value="9">Онлайн магазин</MenuItem>
                        <MenuItem value="10">Друго (свържете се с човек от екипа на “Счетито)</MenuItem>
                    </Select>
                </FormControl>
                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="rent">Ползвате ли помещение под наем за своя бизнес?</InputLabel>
                    <Select
                        id="rent"
                        value={rent}
                        label="Ползвате ли помещение под наем за своя бизнес?"
                        onChange={(e) => setRent(e.target.value)}
                    >
                        <MenuItem value="1">Не</MenuItem>
                        <MenuItem value="2">Да, наемодателят е фирма и получавам фактура за наема</MenuItem>
                        <MenuItem value="3">Да, наемодателя е частно лице и му внасям данъка на 3 месеца</MenuItem>
                        <MenuItem value="4">Да, друго (свържете се с човек от екипа на “Счетито)</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="insurance">Как се осигурявате или ще се осигурявате</InputLabel>
                    <Select
                        id="insurance"
                        value={insurance}
                        label="Как се осигурявате или ще се осигурявате"
                        onChange={(e) => setInsurance(e.target.value)}
                    >
                        <MenuItem value="1">Като самоосигуряващо лице</MenuItem>
                        <MenuItem value="2">С договор за управление</MenuItem>
                        <MenuItem value="3">Друго или не сте сигурни (свържете се с човек от екипа на “Счетито)</MenuItem>
                    </Select>
                </FormControl>

                <FormGroup>
                    <Typography variant="body1" sx={{ mt: 2 }}>
                        Имате ли наети служители?
                    </Typography>
                    <FormControlLabel
                        control={
                            <Radio
                                checked={hiredPeople === true}
                                onChange={() => setHirdePeople(true)}
                            />
                        }
                        label="Да"
                    />
                    <FormControlLabel
                        control={
                            <Radio
                                checked={hiredPeople === false}
                                onChange={() => setHirdePeople(false)}
                            />
                        }
                        label="Не"
                    />
                </FormGroup>

                {hiredPeople && (
                    <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                        В момента нямаме функционалност за наети служители в платформата, очаквайте скоро! Може да предложим обслужване извън платформата - свържете се с нас за повече информация.
                    </Typography>
                )}

                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="cash_register">Ползвате ли касов апарат за извършване на дейността си ?</InputLabel>
                    <Select
                        id="cash_register"
                        value={cash_register}
                        label="Ползвате ли касов апарат за извършване на дейността си ?"
                        onChange={(e) => setCach_register(e.target.value)}
                    >
                        <MenuItem value="1">Не</MenuItem>
                        <MenuItem value="2">Да</MenuItem>
                        <MenuItem value="3">Стартирам нов бизнес (свържете се с човек от екипа на "Счетито")</MenuItem>
                    </Select>
                </FormControl>

                {cash_register === '2' && (
                    <Typography variant="body1">
                        Трябва да разполагате с кочан за касови бележки и кочан за сторно бележки на хартия. Ако нещо не ви е ясно, свържете се с човек от екипа на "Счетито".
                    </Typography>
                )}

                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="bank_account">Имате ли разкрита банкова сметка?</InputLabel>
                    <Select
                        id="bank_account"
                        value={bank_account}
                        label="Имате ли разкрита банкова сметка?"
                        onChange={(e) => setBank_account(e.target.value)}
                    >
                        <MenuItem value="1">Не</MenuItem>
                        <MenuItem value="2">Да, ползвам я само за плащане на осигуровки</MenuItem>
                        <MenuItem value="3">Да, ползвам я за банкови плащания и приемане на плащания по банков път</MenuItem>
                    </Select>
                </FormControl>

                <FormControl sx={{ m: 1 }}>
                    <InputLabel id="poss_account">Приемате ли плащания на ПОС терминал?</InputLabel>
                    <Select
                        id="poss_account"
                        value={poss_account}
                        label="Приемате ли плащания на ПОС терминал?"
                        onChange={(e) => setPoss_account(e.target.value)}
                    >
                        <MenuItem value="1">Не</MenuItem>
                        <MenuItem value="2">Да, ПОС терминала е на банката която ползвам</MenuItem>
                        <MenuItem value="3">Да, ПОС терминала е на друга банка от тази на банковата ми сметка</MenuItem>
                    </Select>
                </FormControl>

                <Typography variant="body1" sx={{ mt: 2 }}>
                    Рекламата във Фейсбук и други социални мрежи, както и закупуване на стоки от ЕС, изисква регистрация по ДДС. Ако възнамерявате да рекламирате в социалните мрежи или да закупувате стоки и консумативи от ЕС, моля преди да го направите се свържете с екипа на “Счетито” за да ви консултираме как да го направите.
                </Typography>

            </Stack>
            {error && (
                <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                    {error}
                </Typography>
            )}
            {serverError && (
                <Typography variant="body1" sx={{ color: 'red', mt: 2 }}>
                    {serverError}
                </Typography>
            )}
            <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                color="inherit"
                onClick={handleSubmit}
                disabled={isDDS}
                sx={{ mt: 3 }}
            >
                Добави фирма
            </LoadingButton>
        </>
    );


    return (
        <Stack alignItems="center" justifyContent="center" sx={{ height: 1 }}>
            <Card
                sx={{
                    p: 5,
                    width: 1,
                }}
            >
                <Typography variant="h4">За да продължите, моля добавете вашата фирма</Typography>
                <Box sx={{ mt: 3 }}>{renderForm}</Box>
            </Card>
        </Stack>
    );
};

export default AddCompanyForm;