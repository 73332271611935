import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    payments: null,
    loading: false,
    error: null,
};

export const subscribeUser = createAsyncThunk(
    'payments/subscribeUser',
    async ({ userId, planLevel, amount }, { rejectWithValue }) => {
        try {
            const response = await api.post('/payment/subscribe', {
                userId,
                planLevel,
                amount
            });

            if (response.status === 200) {
                // Parse the HTML response and extract the form details
                const parser = new DOMParser();
                const doc = parser.parseFromString(response.data, 'text/html');
                const form = doc.querySelector('form'); // Get the form from the response

                if (form) {
                    // Dynamically create a form on the current page and submit it
                    const dynamicForm = document.createElement('form');
                    dynamicForm.action = form.action;
                    dynamicForm.method = form.method;

                    // Copy over all input elements from the response form to the dynamic form
                    Array.from(form.elements).forEach((input) => {
                        const newInput = document.createElement('input');
                        newInput.type = 'hidden';
                        newInput.name = input.name;
                        newInput.value = input.value;
                        dynamicForm.appendChild(newInput);
                    });

                    document.body.appendChild(dynamicForm); // Append the form to the document
                    dynamicForm.submit(); // Submit the form
                } else {
                    return rejectWithValue('Failed to find form in the response');
                }
            } else {
                return rejectWithValue('Failed to initiate subscription');
            }
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getTransactions = createAsyncThunk(
    'payments/getTransactions',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/payment/transactions');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(subscribeUser.pending, (state) => {
                state.loading = true;
            })
            .addCase(subscribeUser.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(subscribeUser.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })

            .addCase(getTransactions.pending, (state) => {
                state.loading = true;
            })
            .addCase(getTransactions.fulfilled, (state, action) => {
                state.payments = action.payload;
                state.loading = false;
            })
            .addCase(getTransactions.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            });
    }
});

export default paymentsSlice.reducer;