import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api'

const initialState = {
    projectAnalysis: null,
    companies: null,
    companyDetails: null,
    invoiceData: null,
    expensesData: null,
    loading: false,
    error: null,
};

export const getProjectAnalysis = createAsyncThunk(
    'admin/getProjectAnalysis',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/admin/project-analysis');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getCompanies = createAsyncThunk(
    'admin/getCompanies',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/admin/companies');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }   
);

export const getCompanyDetails = createAsyncThunk(
    'admin/getCompanyDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/admin/companies/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getInvoiceDataDetails = createAsyncThunk(
    'admin/getInvoiceDataDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/admin/invoices/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

export const getExpenseDataDetails = createAsyncThunk(
    'admin/getExpenseDataDetails',
    async (id, { rejectWithValue }) => {
        try {
            const response = await api.get(`/admin/expenses/${id}`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.message || 'An error occurred');
        }
    }
);

const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getProjectAnalysis.pending, (state) => {
                state.loading = true;
            })
            .addCase(getProjectAnalysis.fulfilled, (state, action) => {
                state.loading = false;
                state.projectAnalysis = action.payload;
                state.error = null;
            })
            .addCase(getProjectAnalysis.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.projectAnalysis = null;
            })
            .addCase(getCompanies.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanies.fulfilled, (state, action) => {
                state.loading = false;
                state.companies = action.payload;
                state.error = null;
            })
            .addCase(getCompanies.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.companies = null;
            })
            .addCase(getCompanyDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getCompanyDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.companyDetails = action.payload;
                state.error = null;
            })
            .addCase(getCompanyDetails.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.companyDetails = null;
            })
            .addCase(getInvoiceDataDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getInvoiceDataDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.invoiceData = action.payload;
                state.error = null;
            })
            .addCase(getInvoiceDataDetails.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.invoiceData = null;
            })
            .addCase(getExpenseDataDetails.pending, (state) => {
                state.loading = true;
            })
            .addCase(getExpenseDataDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.expensesData = action.payload;
                state.error = null;
            })
            .addCase(getExpenseDataDetails.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
                state.expensesData = null;
            })
    }
});

export default adminSlice.reducer;