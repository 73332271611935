import Iconify from '../../components/iconify';

// ----------------------------------------------------------------------

const navConfig = [
  {
    title: 'Табло',
    path: '/',
    icon: <Iconify icon="mdi:view-dashboard" />,
  },
  {
    title: 'Разходи',
    path: '/expenses',
    icon: <Iconify icon="mdi:cash-minus" />,
  },
  {
    title: "Приходи",
    path: '/incomes',
    icon: <Iconify icon="mdi:cash-plus" />,
  },
  {
    title: "Справки",
    path: '/warehouse',
    icon: <Iconify icon="mdi:file-document-outline" />,
  },
  {
    title: "Осигурителен доход",
    path: '/social-insurance',
    icon: <Iconify icon="mdi:shield-check" />,
  },
  {
    title: "Импорт/Експорт на средства",
    path: '/import-export-money',
    icon: <Iconify icon="mdi:transfer" />,
  },
  {
    title: 'Файлове',
    path: '/upload',
    icon: <Iconify icon="mdi:file-upload" />,
  }
];

export default navConfig;
