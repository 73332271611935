import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { startTransition } from 'react';

import usersReducer from './features/users'
import expensesReducer from './features/expenses'
import clientsReducer from './features/clients'
import invoicesReducer from './features/invoices'
import monthReport from './features/month-report';
import insuranceReducer from './features/insurance';
import importExportMoneyRerducer from './features/importExportMoney';
import paymentsReducer from './features/payments';
import adminReducer from './features/admin';
import filesReducer from './features/files';

const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  users: usersReducer,
  expenses: expensesReducer,
  clients: clientsReducer,
  invoices: invoicesReducer,
  monthReport: monthReport,
  insurances: insuranceReducer,
  importExportMoney: importExportMoneyRerducer,
  payments: paymentsReducer,
  admin: adminReducer,
  files: filesReducer
});


// Handle logout in rootReducer
const rootReducer = (state, action) => {
  if (action.type === 'users/logoutUser') {
    // Reset only the users slice to its initial state
    state = {
      ...state,
      users: undefined, // Reset the users slice to its initial state (defined in the usersReducer)
    };
    localStorage.removeItem('token'); // Remove the token on logout
  }
  return reducers(state, action);
}

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };