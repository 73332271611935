import SvgColor from '../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`../../assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const navConfig = [
  {
    title: 'Табло',
    path: '/admin',
    icon: icon('tablo'),
  },
  {
    title: 'Фирми',
    path: '/admin/companies',
    icon: icon('tablo'),
  }
];

export default navConfig;
