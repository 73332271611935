import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../app/api';

// Define the initial state
const initialState = {
    files: [],
    folders: [],
    currentFolder: null,
    breadcrumbs: [],
    loading: false,
    error: null,
    uploadProgress: 0,
    uploadSuccess: false,
};

// Upload files
export const uploadFiles = createAsyncThunk(
    'files/upload',
    async (formData, { rejectWithValue }) => {
        try {
            const response = await api.post('/files/upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total
                    );
                    // You can dispatch an action here to update upload progress
                }
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Error uploading files');
        }
    }
);

// Get user's files
export const getUserFiles = createAsyncThunk(
    'files/getUserFiles',
    async (_, { rejectWithValue }) => {
        try {
            const response = await api.get('/files/files');
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Error fetching files');
        }
    }
);

// Delete file
export const deleteFile = createAsyncThunk(
    'files/deleteFile',
    async (filename, { rejectWithValue }) => {
        try {
            const response = await api.delete(`/files/${filename}`);
            return { filename, ...response.data };
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Error deleting file');
        }
    }
);

// Add new thunks
export const createFolder = createAsyncThunk(
    'files/createFolder',
    async ({ name, parentId }, { rejectWithValue }) => {
        try {
            const response = await api.post('/files/folders', { name, parentId });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Error creating folder');
        }
    }
);

export const getFolderContents = createAsyncThunk(
    'files/getFolderContents',
    async (folderId, { rejectWithValue }) => {
        try {
            const url = folderId ? `/files/folders/${folderId}` : '/files/folders';
            
            const response = await api.get(url);
            
            if (!response.data) {
                throw new Error('No data received from server');
            }

            return {
                folders: response.data.folders || [],
                files: response.data.files || [],
                currentFolder: folderId
            };
        } catch (error) {
            console.error('Error in getFolderContents:', error);
            return rejectWithValue(
                error.response?.data?.message || 
                error.message || 
                'Error fetching folder contents'
            );
        }
    }
);

export const deleteFolder = createAsyncThunk(
    'files/deleteFolder',
    async (folderId, { rejectWithValue }) => {
        try {
            await api.delete(`/files/folders/${folderId}`);
            return folderId;
        } catch (error) {
            return rejectWithValue(error.response?.data?.message || 'Error deleting folder');
        }
    }
);

// Define the slice
const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        resetUploadStatus: (state) => {
            state.uploadSuccess = false;
            state.uploadProgress = 0;
            state.error = null;
        },
        setUploadProgress: (state, action) => {
            state.uploadProgress = action.payload;
        },
        setCurrentFolder: (state, action) => {
            state.currentFolder = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            // Upload Files
            .addCase(uploadFiles.pending, (state) => {
                state.loading = true;
                state.error = null;
                state.uploadSuccess = false;
            })
            .addCase(uploadFiles.fulfilled, (state, action) => {
                state.loading = false;
                state.files = [...state.files, ...action.payload.files];
                state.uploadSuccess = true;
                state.uploadProgress = 100;
            })
            .addCase(uploadFiles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
                state.uploadSuccess = false;
                state.uploadProgress = 0;
            })
            // Get User Files
            .addCase(getUserFiles.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getUserFiles.fulfilled, (state, action) => {
                state.loading = false;
                state.files = action.payload.files;
            })
            .addCase(getUserFiles.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete File
            .addCase(deleteFile.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFile.fulfilled, (state, action) => {
                state.loading = false;
                state.files = state.files.filter(file => file.filename !== action.payload.filename);
            })
            .addCase(deleteFile.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Create Folder
            .addCase(createFolder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createFolder.fulfilled, (state, action) => {
                state.loading = false;
                state.folders = [...state.folders, action.payload];
            })
            .addCase(createFolder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Get Folder Contents
            .addCase(getFolderContents.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getFolderContents.fulfilled, (state, action) => {
                state.loading = false;
                state.folders = action.payload.folders;
                state.files = action.payload.files;
                state.currentFolder = action.payload.currentFolder;
            })
            .addCase(getFolderContents.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            })
            // Delete Folder
            .addCase(deleteFolder.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(deleteFolder.fulfilled, (state, action) => {
                state.loading = false;
                state.folders = state.folders.filter(folder => folder.id !== action.payload);
            })
            .addCase(deleteFolder.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

// Export actions
export const { resetUploadStatus, setUploadProgress, setCurrentFolder } = filesSlice.actions;

// Export reducer
export default filesSlice.reducer;
