import React, { Suspense, startTransition } from 'react';
import { Navigate, Outlet } from 'react-router-dom';


import { logoutUser } from '../features/users'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import DashboardLayout from '../layouts/dashboard';
import AdminLayout from '../layouts/admin';

const PrivateRoute = ({ isAdmin = false }) => {
    const { isAuthenticated, user } = useSelector((state) => state.users);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    if (!isAuthenticated) {
        localStorage.removeItem('token');
        startTransition(() => {
            dispatch(logoutUser());
            navigate('/login');
        });
        return null;
    }

    const Layout = isAdmin ? AdminLayout : DashboardLayout;

    return (
        <Layout>
            <Suspense>
                <Outlet />
            </Suspense>
        </Layout>
    );
}

export default PrivateRoute;